<template>
  <div>
    <el-skeleton
      :loading="loading"
      :rows="4"
      animated
    />
    <div v-if="!loading">
      <el-card>
        <div
          slot="header"
          class="clearfix"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Vendors By Inherent Risk Rating"
            placement="top-start"
          >
            <strong>Vendors By IRR <i class="el-icon-question" /></strong>
          </el-tooltip>
        </div>
        <highcharts
          :options="highChart"
        />
      </el-card>
    </div>
  </div>
</template>

<script>
import {
// BCard, BCardBody, BAvatar,
} from 'bootstrap-vue'
import Resource from '@/api/resource'

export default {
  components: {},
  data() {
    return {
      loading: false,
      highChart: {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 10,
            beta: 0,
          },
        },
        title: {
          text: '',
          align: 'center',
        },
        subtitle: {
          text: '',
        },
        tooltip: {
          pointFormat: '{series.name}:<b>{point.y}</b><br>{point.percentage:.1f}%',
          // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            // valueSuffix: '%',
          },
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 8,
            innerSize: 150, // what makes it donut
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}:</b> {point.y}<br>{point.percentage:.1f} %',
              // format: '<b>{point.name}:</b> {point.y}<br>{point.percentage:.1f} %',
              // format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
            showInLegend: true,
          },
        },
        colors: ['#34db31', '#db9731', '#db3131'],
        series: [],
        credits: {
          enabled: false,
        },
      },
    }
  },
  mounted() {
    this.fetchVendorsRatings()
  },
  methods: {
    fetchVendorsRatings() {
      const app = this
      app.loading = true
      const fetchReportResource = new Resource('vdd/reports/vendor-onboarding-report')
      fetchReportResource.list({ chart_type: 'ratings' })
        .then(response => {
          app.highChart.series = response.series
          app.loading = false
        }).catch(() => { app.loading = false })
    },
  },
}
</script>
